import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import '../../App.css';

class GalleryGroupEdit extends Component {

    constructor(props) {
        super(props);
        this.storageRef = firebase.storage().ref('gallery_groups');
        this.state = {
        key: '',
        title: '',
        image_url: ''
        };
    }

    componentDidMount() {
        const ref = firebase.database().ref(`gallery_groups/${this.props.match.params.id}`);
        
        ref.on('value', snapshot => {
            let group = snapshot.val();
            if (group) {
                this.setState({
                    key: this.props.match.params.id,
                    title: group.title,
                    image_url: group.image_url
                });
            } else {
                console.log("No such group!");
            }        
        });
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({board:state});
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.title === ''){
            alert('Please enter group title..');
        }
        else if (this.state.image_url === ''){
            alert('Please upload image..');
        }        
        else{
            const updateRef = firebase.database().ref(`gallery_groups/${this.state.key}`);
            updateRef.set({
                title:this.state.title,
                image_url: this.state.image_url
            }).then((docRef) => {
                this.setState({
                    key: '',
                    title: '',
                    image_url: ''
                });
                this.props.history.push("/gallery_groups/list");
            })
            .catch((error) => {
                console.error("Error updating gallery group: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedFile = image;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{        
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({image_url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }    

    render() {
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Edit Gallery Group
                    <Link className="btn btn-primary float-right" to={`/gallery_groups/list`}>Gallery Group List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onSubmit}>
              
                        <div className="form-group">
                            <label htmlFor="title">Group Title:</label>
                            <input type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChange} placeholder="Group Title" />
                        </div>

                        <div className="form-group">
                            <input type="file" onChange={this.handleSelectFile} />
                            <button type="button" onClick={this.uploadFile}>Upload</button>
                        </div>                        
                        <div className="form-group">
                            <label htmlFor="image_url">Image Url:</label>
                            <input type="text" className="form-control" name="image_url" value={this.state.image_url} onChange={this.onChange} placeholder="Image Url" />
                        </div>

                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default GalleryGroupEdit;