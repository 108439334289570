import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('users');   
        this.ref_groups = firebase.database().ref('user_groups');
        this.loggedIn = false; 
        this.mounted = false;
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false, 
            search_value: '',       
            users: [],
            filtered_users: [],
            groups: []
        };
    }

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true });

            this.ref_groups.on('value', snapshot => {
                let grps = [];
                let groups = snapshot.val();
                for (let group in groups) {
                    grps.push({
                    key: group,
                    group_name: groups[group].group_name
                    });
                };
                this.setState({ groups: grps });
            });

            this.ref.on('value', snapshot => {
                let usrs = [];
                let users = snapshot.val();
                for (let user in users) {
                    //const { userName, userDOB, userEmail, userPhone, group_id } = users[user];
                    usrs.push({
                    key: user,
                    userName: users[user].userName,
                    userDob: users[user].userDob,
                    userEmail: users[user].userEmail,
                    userPhone: users[user].userPhone,
                    group_id: users[user].group_id
                    });
                };
                this.setState({ users: usrs.sort(this.getSortOrder("userName")), filtered_users: usrs.sort(this.getSortOrder("userName")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    } 

    getGroupName(id){
        let grps = this.state.groups;
        let grp = grps.filter(grp=>grp.key===id);
        if (grp[0])
            return grp[0]['group_name'];
        else
            return "";
    }  

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterUsers();
        }        
    }    

    filterUsers(){
        let users = this.state.users;
        let filtered_usrs = users;
        if (this.state.search_value !== ""){
            filtered_usrs = users.filter(user => 
                user.userName.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1
            );
        }
        this.setState({filtered_users:filtered_usrs});
    }

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title col-md-9 float-left">
                            Users List
                            </h3>
                            <input
                                type="text" className="col-md-3 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by User Name..."
                                onChange={this.onChange}
                            />                            
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="width-20">User Name</th>
                                        <th className="width-30">User Email</th>
                                        <th className="width-15">User DOB</th>
                                        <th className="width-15">User Phone</th>
                                        <th className="width-20">User Group</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtered_users.map(user =>
                                    <tr key={user.key}>
                                        <td>{user.userName}</td>
                                        <td><Link to={`/users/edit/${user.key}`}>{user.userEmail}</Link></td>
                                        <td>{user.userDob}</td>
                                        <td>{user.userPhone}</td>
                                        <td>{this.getGroupName(user.group_id)}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
    }
}

export default UserList;
