import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import '../../App.css';

class UserGroupCreate extends Component {

    constructor() {
        super();
        this.ref = firebase.database().ref(`user_groups/${uuid()}`);
        this.storageRef = firebase.storage().ref('user_groups');
        this.state = {
            group_name:''
        };
    }
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { group_name } = this.state;

        if (group_name === ''){
            alert('Please enter user group name..');
        }
        else{
            this.ref.set({
                group_name
            }).then((docRef) => {
                this.setState({
                    group_name:''
                });
                this.props.history.push("/user_groups/list");
            })
            .catch((error) => {
                console.error("Error adding user group: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedFile = image;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }

    render() {
        const { group_name } = this.state;
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Add User Group
                    <Link className="btn btn-primary float-right" to="/user_groups/list">User Group List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label htmlFor="title">User Group Name:</label>
                            <input type="text" className="form-control" name="group_name" value={group_name} onChange={this.onChange} placeholder="Title" />
                        </div>
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default UserGroupCreate;