import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import uuid from 'react-uuid';
import '../../App.css';

class WorkoutEdit extends Component {

    constructor(props) {
        super(props);
        this.storageRef = firebase.storage().ref('activities');
        this.state = {
        key: '',
        no:'',
        type:'1',
        title: '',
        short_desc: '',
        long_desc: '',
        attachment_type:'video',
        attachment_url: '',
        image_url: '',
        questionId:'',
        question:'',        
        questions:{},
        questions_array:[],
        options:[],
        mounted: false,
        loading: false
        };
    }

    componentDidMount() {
        this.setState({loading: true });
        const ref = firebase.database().ref(`activities/${this.props.match.params.id}`);
        
        ref.on('value', snapshot => {
            let activity = snapshot.val();
            if (activity) {
                this.setState({
                    key: this.props.match.params.id,
                    no: activity.no,
                    type: activity.type === undefined? '1': activity.type,
                    title: activity.title,
                    short_desc: activity.short_desc === undefined? '': activity.short_desc,
                    long_desc: activity.long_desc === undefined? '': activity.long_desc,
                    attachment_type: activity.attachment_type,
                    attachment_url: activity.attachment_url,
                    image_url: activity.image_url,
                    questions: activity.questions === undefined? {}:activity.questions
                });
                this.loadQuestions(activity);
                this.setState({loading: false, mounted: true });
            } else {
                console.log("No such Exercise!");
            }        
        });
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        if (state.attachment_type === 'video' && (e.target.name === 'attachment_url' || e.target.name === 'attachment_type')){
            state.image_url = state.attachment_url;
        }        
        this.setState({board:state});
    }

    loadQuestions(activity){
        var question_array = [];
        var questions = activity.questions === undefined? {}:activity.questions; 
        console.log(activity);       
        for (const question in questions) {
            question_array.push({"id":question,"question":questions[question]['question'],"type":questions[question]['type']})
        }
        this.setState({'questions_array':question_array});
    }

    loadOptions(questionId){
        var option_array = [];
        var question = this.state.questions[questionId]['question']; 
        var options = this.state.questions[questionId]['options'];        
        for (const option in options) {
            option_array.push({"id":option, "value":options[option]})
        }
        this.setState({'questionId':questionId, 'question':question, 'options':option_array});
    }

    addQuestion(){
        if (this.checkQuestionData() === true){
            const state = this.state;
            var questions = this.state.questions;
            var questions_arr = this.state.questions_array;
            var counter = this.state.counter + 1;
            var questionId = uuid() + counter.toString();
            questions[questionId]={"question":"","options":{"1":"","2":"","3":"","4":"","5":"","6":""},"type":"Single"};
            questions_arr.push({"id":questionId,"question":"","type":"Single"})

            state['counter'] = counter;
            state['questions'] = questions;
            state['questions_array'] = questions_arr;
            this.setState(state);
        }
    }

    onColumnChange(type, id, name, value) {
        if (type === 'Question'){
            let arr = this.state.questions_array;
            let act_questions = this.state.questions;

            arr.forEach(question =>{
                if (question.id === id){
                    question[name] = value;
                    act_questions[id][name] = value;
                }
            });

            this.setState({questions_array:arr, questions:act_questions});
        }
        else if (type === 'Option'){
            let arr = this.state.options;
            let act_questions = this.state.questions;

            arr.forEach(option =>{
                if (option.id === id){
                    option[name] = value;
                    act_questions[this.state.questionId]["options"][id] = value;
                }
            });

            this.setState({options:arr, questions:act_questions});
        }
    }

    delQuestion(id){
        let arr = this.state.questions_array.filter(question=> question.id!==id);
        this.setState({questions_array: arr});

        let act_questions = this.state.questions;
        delete act_questions[id];

        if (this.state.questionId === id){
            this.setState({options:[], questionId:'', question:'', questions_array:arr, questions:act_questions});
        }
        else{
            this.setState({questions_array:arr, questions:act_questions});
        }
    }    

    checkQuestionData(){
        var isValid = true;
        var arr = this.state.questions_array;
        if (arr.length > 0){
            arr.forEach(question =>{
                if (isValid){
                    if (question.question === ""){
                        alert('Question Not Filled..');
                        isValid = false;
                    }
                    else
                    {
                        isValid = false;
                        var options = this.state.questions[question.id]['options'];        
                        for (const option in options) {
                            if (options[option] !== ""){
                                isValid = true;
                            }
                        }
                        if (!isValid){
                            alert('No Option Filled for a Question..');
                        }                    
                    }
                }
            });
        }       
        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.title === ''){
            alert('Please enter title..');
        }
        else if (this.state.no === ''){
            alert('Please enter Exercise #..');
        }
        else if (this.state.type !== '4' && this.state.attachment_type !== 'text' && this.state.attachment_url === ''){
            alert('Please upload file / enter content url..');
        }
        else if (this.state.type !== '4' && this.state.attachment_type !== 'text' && this.state.image_url === ''){
            alert('Please upload file / enter image url..');
        }      
        else if (!this.checkQuestionData()){
        }          
        else{
            const updateRef = firebase.database().ref(`activities/${this.state.key}`);
            updateRef.set({
                no:this.state.no,
                type:this.state.type,
                title: this.state.title,
                short_desc: this.state.short_desc,
                long_desc: this.state.long_desc,
                attachment_type:this.state.attachment_type,
                attachment_url: this.state.attachment_url,
                image_url: this.state.image_url,
                questions: this.state.questions
            }).then((docRef) => {
                this.setState({
                    key: '',
                    no:'',
                    type:'1',
                    title: '',
                    short_desc: '',
                    long_desc: '',
                    attachment_type:'video',
                    attachment_url: '',
                    image_url: '',
                    questions: {}
                });
                this.props.history.push("/activities/list");
            })
            .catch((error) => {
                console.error("Error updating Exercise: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedFile = image;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select content file..');
        }
        else{        
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({attachment_url:fileurl});
                    alert('Content File uploaded successfully !!');
                })
            });
        }
    }    

    handleSelectImageFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedImageFile = image;
    }

    uploadImageFile = (e) => {
        e.preventDefault();
        if (this.selectedImageFile === undefined || this.selectedImageFile === null || this.selectedImageFile === "")
        {
            alert('Please select image file..');
        }
        else{
            const selectedFile = this.storageRef.child(this.selectedImageFile.name);
            selectedFile.put(this.selectedImageFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({image_url:fileurl});
                    alert('Image File uploaded successfully !!');
                })
            });
        }
    }    

    render() {
        if (this.state.mounted === false || this.state.loading === true){
            return (<div className="container">Loading..</div>);
        }
        else
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Edit Exercise
                    <Link className="btn btn-primary float-right" to={`/activities/list`}>Exercise List</Link>
                    </h3>
                </div>
                <div className="card-body scrollable-card-body">
                    <form onSubmit={this.onSubmit}>
                    <div className="row">
                            <div className="form-group col-md-2">
                                <label htmlFor="attachment_type">Content Type:</label>
                                <select className="form-control" name="attachment_type" value={this.state.attachment_type} onChange={this.onChange} placeholder="Select Content-Type">
                                    <option value="video">Video</option>
                                    <option value="image">Image</option>
                                    <option value="pdf">PDF</option>
                                    <option value="text">Text</option>
                                </select>
                            </div>      
                            <div className="form-group col-md-2">
                                <label htmlFor="type">Type:</label>
                                <select className="form-control" name="type" value={this.state.type} onChange={this.onChange} placeholder="Select Type">
                                    <option value="1">Warm Up</option>
                                    <option value="2">Exercise</option>
                                    <option value="3">Cool Down</option>
                                    <option value="4">Rest</option>
                                </select>
                            </div>                                                  
                            <div className="form-group col-md-2">
                                <label htmlFor="no">Exercise #:</label>
                                <input type="text" className="form-control" name="no" value={this.state.no} onChange={this.onChange} placeholder="Activity #" />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="title">Title:</label>
                                <input type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChange} placeholder="Title" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="short_desc">Short Description:</label>
                                <textarea className="form-control" name="short_desc" value={this.state.short_desc} onChange={this.onChange} placeholder="Short Description" cols="80" rows="2"></textarea>
                                {/* <input type="text" className="form-control" name="short_desc" value={this.state.short_desc} onChange={this.onChange} placeholder="Short Description" /> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="long_desc">Long Description:</label>
                                <textarea className="form-control" name="long_desc" value={this.state.long_desc} onChange={this.onChange} placeholder="Description" cols="80" rows="2"></textarea>
                                {/* <input type="text" className="form-control" name="long_desc" value={this.state.long_desc} onChange={this.onChange} placeholder="Long Description" /> */}
                            </div>    
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <input type="file" onChange={this.handleSelectFile} />
                                <button type="button" onClick={this.uploadFile}>Upload Content File</button>
                            </div>
                            <div className="form-group col-md-6">
                                <input type="file" onChange={this.handleSelectImageFile} />
                                <button type="button" onClick={this.uploadImageFile}>Upload Image File</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="attachment_url">Content Url:</label>
                                <textarea className="form-control" name="attachment_url" value={this.state.attachment_url} onChange={this.onChange} placeholder="Content Url" cols="80" rows="2"></textarea>
                                {/* <input type="text" className="form-control" name="attachment_url" value={this.state.attachment_url} onChange={this.onChange} placeholder="Content Url" /> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="image_url">Image Url:</label>
                                <textarea className="form-control" name="image_url" value={this.state.image_url} onChange={this.onChange} placeholder="Image Url" cols="80" rows="2"></textarea>
                                {/* <input type="text" className="form-control" name="image_url" value={this.state.image_url} onChange={this.onChange} placeholder="Image Url" /> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="width-100">
                                    <h5>
                                        <Link className="btn btn-primary float-left" to="#" onClick={()=>this.addQuestion()}>Add Question</Link>
                                    </h5>
                                </div>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-60">Question</th>
                                            <th className="width-20">Type</th>
                                            <th className="width-10">..</th>
                                            <th className="width-10">..</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questions_array.map(question =>
                                        <tr key={question.id}>
                                            <td>
                                                <input type="text" className="form-control" value={question.question} 
                                                onChange={e=>this.onColumnChange('Question',question.id,'question',e.target.value)} 
                                                placeholder="Question" />
                                            </td>
                                            <td>
                                            <select className="form-control" value={question.type} 
                                                onChange={e=>this.onColumnChange('Question',question.id,'type',e.target.value)}
                                                placeholder="Select Type">
                                                <option value="Single">Single</option>
                                                <option value="Multi">Multi</option>
                                            </select>                                                
                                            </td>
                                            <td><Link to="#" onClick={()=>this.loadOptions(question.id)}>Options</Link></td>
                                            <td><Link to="#" onClick={()=>this.delQuestion(question.id)}>Remove</Link></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>  
                            </div>
                            {this.state.questionId !== "" ?
                            <div className="col-md-4">
                                <div className="width-100">
                                    <p>Question : {this.state.question}</p>
                                </div>
                                {/* <div className="width-100">
                                    <Link className="btn btn-primary" to="#" onClick={()=>this.addOption()}>Add Option</Link>
                                </div> */}
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="width-100">Option</th>
                                            {/* <th className="width-30">..</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.options.map(option =>
                                        <tr key={option.id}>
                                            <td>
                                                <input type="text" className="form-control" value={option.value} 
                                                onChange={e=>this.onColumnChange('Option',option.id,'value',e.target.value)} 
                                                placeholder="Option" />
                                            </td>
                                            {/* <td><Link to="#" onClick={()=>this.delOption(option.id)}>Remove</Link></td> */}
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>                                
                            </div>
                            :<div className="col-md-4"></div>}
                        </div>                        
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default WorkoutEdit;