import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class AssignWorkouts extends Component {
    constructor(props) {
        super(props);
        this.ref_workouts = firebase.database().ref('workouts_v2');
        this.loggedIn = false; 
        this.mounted = false;
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false, 
            search_value: '',       
            group_workouts: [],
            workouts: [],
            filtered_workouts: [],
            selectedGroupId:"",
            selectedGroupName:""
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }
    
    getSortOrderDesc(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return -1;    
            } else if (a[prop] < b[prop]) {    
                return 1;    
            }    
            return 0;    
        }    
    }

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            
            const ref = firebase.database().ref(`user_groups/${this.props.match.params.id}`);
            this.ref_group_workouts = firebase.database().ref(`user_group_workouts/${this.props.match.params.id}`);
            
            this.setState({loggedIn: true, loading: true });

            this.ref_group_workouts.on('value', snapshot => {
                let grpwrks = [];
                let groupwos = snapshot.val();
                for (let wrk in groupwos) {
                    grpwrks.push({
                    key: wrk,
                    title: groupwos[wrk].title,
                    s_date: groupwos[wrk].s_date,
                    e_date: groupwos[wrk].e_date,
                    active: groupwos[wrk].active,
                    });
                };
                this.setState({ group_workouts: grpwrks.sort(this.getSortOrderDesc("s_date")) });
            });
            
            this.ref_workouts.on('value', snapshot => {
                let wrks = [];
                let workouts = snapshot.val();
                var currDate = new Date();
                for (let wrk in workouts) {
                    if (new Date(workouts[wrk].e_date) >= currDate)
                        wrks.push({
                        key: wrk,
                        title: workouts[wrk].title,
                        s_date: workouts[wrk].s_date,
                        e_date: workouts[wrk].e_date
                        });
                };
                this.setState({ workouts: wrks.sort(this.getSortOrder("title")), filtered_workouts: wrks.sort(this.getSortOrder("title")) });
            });            

            ref.on('value', snapshot => {
                let user_group = snapshot.val();
                if (user_group) {
                    this.setState({
                        selectedGroupId: this.props.match.params.id,
                        selectedGroupName: user_group.group_name,
                        loading: false
                    });
                }
            });
        }
        this.setState({mounted: true});
    }

    toggleStatus(workout){
        const ref_userGroup = firebase.database().ref(`user_group_workouts/${this.state.selectedGroupId}`);
        ref_userGroup.child(workout.key).update({active:workout.active===1?0:1});

        let group_wos = this.state.group_workouts;
        group_wos.forEach(wo=>{
            if (wo.key===workout.key)
                wo.active = workout.active===1?0:1;
        });
        this.setState({group_workouts:group_wos});
    }

    assignWorkout(wo){
        let group_workout = this.state.group_workouts; 
        let assignedWO = this.state.group_workouts.filter(workout=>workout.key===wo.key);
        if (assignedWO.length === 0){

            var new_s_date = new Date(wo.s_date);
            var new_e_date = new Date(wo.e_date);
            var dateMismatch = false;

            group_workout.forEach(workout => {
                var s_date = new Date(workout.s_date);
                var e_date = new Date(workout.e_date);

                if ((new_s_date >= s_date && new_s_date <= e_date) || (new_e_date >= s_date && new_e_date <= e_date)){
                    dateMismatch = true;
                }
            });

            if (dateMismatch === false){

                const ref_userGroup = firebase.database().ref(`user_group_workouts/${this.state.selectedGroupId}`);
                ref_userGroup.child(wo.key).set({title:wo.title, s_date:wo.s_date, e_date:wo.e_date, active:0});

                firebase.database().ref(`workouts_v2/${wo.key}`).update({assigned:1});

                group_workout.push({"key":wo.key, "title":wo.title, "s_date":wo.s_date, "e_date":wo.e_date, "active":0});
                this.setState({group_workouts: group_workout});

            }
            else{
                alert("Cannot Assign Workout, Start & End Date Issue");
            }    
        }
        else
        {
            alert('Workout already assigned !!');
        }
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterWorkouts();
        }        
    }    

    filterWorkouts(){
        let workouts = this.state.workouts;
        let filtered_wos = workouts;
        if (this.state.search_value !== ""){
            var searchDate = new Date(this.state.search_value);
            filtered_wos = workouts.filter(wo => 
                new Date(wo.s_date) <= searchDate && new Date(wo.e_date) >= searchDate
            );
        }
        this.setState({filtered_workouts:filtered_wos});
    }    

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="width-50 float-left">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">
                                Assigned Workouts - {this.state.selectedGroupName}
                                </h4>
                            </div>
                            
                            <div className="card-body">
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-50">Title</th>
                                            {/* <th className="width-15">Start Date</th> */}
                                            <th className="width-20">End Date</th>
                                            <th className="width-10">Active</th>
                                            <th className="width-20">..</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.group_workouts.map(wo =>
                                        <tr key={wo.key}>
                                            <td>{wo.title}</td>
                                            {/* <td>{wo.s_date}</td> */}
                                            <td>{new Intl.DateTimeFormat('en-GB').format(new Date(wo.e_date))}</td>
                                            <td>{wo.active===1?"Yes":"No"}</td>
                                            <td>
                                                <Link to="#" onClick={()=>this.toggleStatus(wo)}>{wo.active===1?"De-Activate":"Activate"}</Link>
                                            </td>
                                            
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div> 

                    <div className="width-50 float-right">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title width-60 float-left">
                                Workouts
                                </h4>
                                <input
                                type="date" className="col-md-4 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by Date..."
                                onChange={this.onChange}
                            />                                
                            </div>
                            
                            <div className="card-body">
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-50">Title</th>
                                            <th className="width-18">Start Date</th>
                                            <th className="width-18">End Date</th>
                                            <th className="width-14">..</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.filtered_workouts.map(wo =>
                                        <tr key={`key-${wo.key}`}>
                                            <td>{wo.title}</td>
                                            <td>{new Intl.DateTimeFormat('en-GB').format(new Date(wo.s_date))}</td>
                                            <td>{new Intl.DateTimeFormat('en-GB').format(new Date(wo.e_date))}</td>
                                            <td>
                                                <Link to="#" onClick={()=>this.assignWorkout(wo)}>Assign</Link>
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                </div>
            );
    }
}

export default AssignWorkouts;
