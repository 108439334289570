import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';

const loading = (
  <div className="pt-3 text-center">
    <div>Loading..</div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./components/login'));

function App() {
  return (
    <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
        </React.Suspense>
    </HashRouter>
  );
  // return (
  //   <div className="App">

  //     <header className="App-header">
  //       <ul>
  //         <li><a href="/gallery/list">Gallery</a></li>
  //         <li><a href="/workouts/list">Workouts</a></li>
  //       </ul>
  //     </header>
  //     <div className="App-body">
  //     </div>
  //   </div>
  // );
}

export default App;
