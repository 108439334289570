import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import '../../App.css';

class UserEdit extends Component {

    constructor(props) {
        super(props);
        this.ref_groups = firebase.database().ref('user_groups');
        this.storageRef = firebase.storage().ref('users');
        this.state = {
        key: '',
        userName: '',
        userDob: '',
        userEmail: '',
        userPhone: '',
        group_id: '',
        groups: []
        };
    }

    componentDidMount() {

        this.ref_groups.on('value', snapshot => {
            let grps = [];
            let groups = snapshot.val();
            for (let group in groups) {
                grps.push({
                key: group,
                group_name: groups[group].group_name
                });
            };
            this.setState({ groups: grps });
        });

        const ref = firebase.database().ref(`users/${this.props.match.params.id}`);
        
        ref.on('value', snapshot => {
            let user = snapshot.val();
            if (user) {
                this.setState({
                    key: this.props.match.params.id,
                    userName: user.userName,
                    userDob: user.userDob,
                    userEmail: user.userEmail,
                    userPhone: user.userPhone,
                    group_id: user.group_id
                });
            } else {
                console.log("No such user!");
            }        
        });
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({board:state});
    }

    onSubmit = (e) => {
        e.preventDefault();

        //const { content_type, title, description, url } = this.state;

        if (this.state.group_id === ''){
            alert('Please select user group..');
        }
        else{
            const updateRef = firebase.database().ref(`users/${this.state.key}`);
            updateRef.set({
                userName: this.state.userName,
                userDob: this.state.userDob,
                userEmail: this.state.userEmail,
                userPhone: this.state.userPhone,
                group_id: this.state.group_id
            }).then((docRef) => {
                this.setState({
                    key: '',
                    userName: '',
                    userDob: '',
                    userEmail: '',
                    userPhone: '',
                    group_id: ''
                });
                this.props.history.push("/users/list");
            })
            .catch((error) => {
                console.error("Error updating users: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedFile = image;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{        
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }    

    render() {
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Edit User
                    <Link className="btn btn-primary float-right" to={`/users/list`}>Users List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label htmlFor="title">User Name:</label>
                            <input type="text" readOnly="true" className="form-control" name="userName" value={this.state.userName} onChange={this.onChange} placeholder="Title" />
                        </div>                
                        <div className="form-group">
                            <label htmlFor="title">User Email:</label>
                            <input type="text" readOnly="true" className="form-control" name="userEmail" value={this.state.userEmail} onChange={this.onChange} placeholder="Title" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">User DOB:</label>
                            <input type="text" readOnly="true" className="form-control" name="userDob" value={this.state.userDob} onChange={this.onChange} placeholder="Description" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">User Phone:</label>
                            <input type="text" readOnly="true" className="form-control" name="userPhone" value={this.state.userPhone} onChange={this.onChange} placeholder="Description" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="content_type">User Group:</label>
                            <select className="form-control" name="group_id" value={this.state.group_id} onChange={this.onChange} placeholder="Select User Group">
                            <option key="-" value="">Select User Group</option>
                            {this.state.groups.map(group =>
                                <option key={group.key} value={group.key}>{group.group_name}</option>
                            )}
                            </select>
                        </div>                        
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default UserEdit;