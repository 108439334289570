import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import '../../App.css';

class GalleryGroupCreate extends Component {

    constructor() {
        super();
        this.ref = firebase.database().ref(`gallery_groups/${uuid()}`);
        this.storageRef = firebase.storage().ref('gallery_groups');
        this.state = {
            title:'',
            image_url: ''
        };
    }
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();

        //const { title, image_url } = this.state;

        if (this.state.title === ''){
            alert('Please enter group title..');
        }
        else if (this.state.image_url === ''){
            alert('Please upload image..');
        }        
        else{
            this.ref.set({
                title: this.state.title,
                image_url: this.state.image_url
            }).then((docRef) => {
                this.setState({
                    title: '',
                    image_url: ''
                });
                this.props.history.push("/gallery_groups/list");
            })
            .catch((error) => {
                console.error("Error adding gallery group: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        this.selectedFile = image;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({image_url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }

    render() {
        const { title, image_url } = this.state;
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Add Gallery Group
                    <Link className="btn btn-primary float-right" to="/gallery_groups/list">Gallery Group List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label htmlFor="title">Group Title:</label>
                            <input type="text" className="form-control" name="title" value={title} onChange={this.onChange} placeholder="Title" />
                        </div>

                        <div className="form-group">
                            <input type="file" onChange={this.handleSelectFile} />
                            <button type="button" onClick={this.uploadFile}>Upload</button>
                        </div>                        
                        <div className="form-group">
                            <label htmlFor="image_url">Image Url:</label>
                            <input type="text" className="form-control" name="image_url" value={image_url} onChange={this.onChange} placeholder="Image Url" />
                        </div>

                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default GalleryGroupCreate;