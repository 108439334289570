import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import '../../App.css';

class WorkoutEdit extends Component {

    constructor(props) {
        super(props);
        this.ref_activities = firebase.database().ref('activities');
        this.ref_group_workouts = firebase.database().ref(`user_group_workouts`);
        this.storageRef = firebase.storage().ref('workouts_v2');
        this.state = {
        key: '',
        title: '',
        description: '',
        s_date: '',
        e_date: '',
        day_period: '-',
        search_value: '',
        activities: [],
        all_activities: [],
        filtered_activities: [],
        //user_groups: [],
        sun_am:{},
        sun_pm:{},
        mon_am:{},
        mon_pm:{},
        tue_am:{},
        tue_pm:{},
        wed_am:{},
        wed_pm:{},
        thu_am:{},
        thu_pm:{},
        fri_am:{},
        fri_pm:{},
        sat_am:{},
        sat_pm:{},
        assigned:0,
        invalid_date:''
        };
    }

    componentDidMount() {
        const ref = firebase.database().ref(`workouts_v2/${this.props.match.params.id}`);
        
        this.ref_activities.on('value', snapshot => {
            let acts = [];
            let activities = snapshot.val();
            for (let activity in activities) {
                acts.push({
                key: activity,
                title: activities[activity].title,
                image_url: activities[activity].image_url,
                type: activities[activity].type
                });
            };
            this.setState({ all_activities: acts, filtered_activities: acts });
        });

        // this.ref_group_workouts.on('value', snapshot => {
        //     let grps = [];
        //     let grp_wos = snapshot.val();
        //     for (let grp in grp_wos) {
        //         if (grp_wos[grp][this.props.match.params.id] !== undefined && grp_wos[grp][this.props.match.params.id] !== null)
        //             grps.push({
        //             key: grp,
        //             workorder_id:this.props.match.params.id
        //             });
        //     };
        //     this.setState({ user_groups: grps });
        // });        

        ref.on('value', snapshot => {
            let workout = snapshot.val();
            if (workout) {
                this.setState({
                    key: this.props.match.params.id,
                    title: workout.title,
                    description: workout.description,
                    s_date: workout.s_date===undefined?'':workout.s_date,
                    e_date: workout.e_date===undefined?'':workout.e_date,
                    sun_am: workout.sun_am===undefined?{}:workout.sun_am,
                    sun_pm: workout.sun_pm===undefined?{}:workout.sun_pm,
                    mon_am: workout.mon_am===undefined?{}:workout.mon_am,
                    mon_pm: workout.mon_pm===undefined?{}:workout.mon_pm,
                    tue_am: workout.tue_am===undefined?{}:workout.tue_am,
                    tue_pm: workout.tue_pm===undefined?{}:workout.tue_pm,
                    wed_am: workout.wed_am===undefined?{}:workout.wed_am,
                    wed_pm: workout.wed_pm===undefined?{}:workout.wed_pm,
                    thu_am: workout.thu_am===undefined?{}:workout.thu_am,
                    thu_pm: workout.thu_pm===undefined?{}:workout.thu_pm,
                    fri_am: workout.fri_am===undefined?{}:workout.fri_am,
                    fri_pm: workout.fri_pm===undefined?{}:workout.fri_pm,
                    sat_am: workout.sat_am===undefined?{}:workout.sat_am,
                    sat_pm: workout.sat_pm===undefined?{}:workout.sat_pm,
                    assigned: workout.assigned===undefined?0:workout.assigned
                });
            } else {
                console.log("No such document!");
            }        
        });
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "day_period"){
            this.loadActivities();
        }
        else if (e.target.name === "search_value"){
            this.filterActivities();
        }        
    }

    onColumnChange(activity_id, name, value) {
        const state = this.state;
        let arr = state.activities;
        let day_activities = state[this.state.day_period];

        arr.forEach(act =>{
            if (act.activity_id === activity_id){
                act[name] = value;
                
                day_activities[act.activity_id] = {title:act.title, image_url:act.image_url, type:act.type, no_of_repeats:act.no_of_repeats, no_of_units:act.no_of_units};
            }
        });

        state['activities']=arr;
        state[state.day_period]= day_activities;
        this.setState({state});
    }

    loadActivities(){
        let arr = [];
        if (this.state.day_period !== "-"){
            let activities = this.state[this.state.day_period];
            for (const act in activities) {
                let activity = this.state.all_activities.filter(activity=> activity.key===act)[0];

                if (!activities[act].hasOwnProperty("title"))
                    activities[act] = {title:activity.title, image_url:activity.image_url,no_of_units:'',no_of_repeats:''};

                arr.push({"activity_id":act, "image_url": activity.image_url, "title":activity.title, "type":activity.type, 
                "no_of_repeats":activities[act]["no_of_repeats"], "no_of_units":activities[act]["no_of_units"]});
            }
            this.setState({activities:arr});
        }
        else
        {
            this.setState({activities:[]})
        }
    }

    filterActivities(){
        let activities = this.state.all_activities;
        let filtered_acts = activities;
        if (this.state.search_value !== ""){
            filtered_acts = activities.filter(activity => activity.title.toUpperCase().includes(this.state.search_value.toUpperCase()));
        }
        this.setState({filtered_activities:filtered_acts});
    }    

    delActivitiy(activity_id){
        let arr = this.state.activities.filter(activity=> activity.activity_id!==activity_id);
        this.setState({activities: arr});

        let day_activities = this.state[this.state.day_period];
        delete day_activities[activity_id];

        const state = this.state;
        state[this.state.day_period] = day_activities;
        this.setState({state});
    }

    addActivitiy(activity){
        let arr = this.state.activities;
        let day_activities = this.state[this.state.day_period] !== undefined ? this.state[this.state.day_period] : {};

        if (activity.type === undefined) activity['type']='';
        if (day_activities[activity.key] === null || day_activities[activity.key] === undefined){
            day_activities[activity.key] = {title:activity.title, image_url:activity.image_url, type:activity.type, no_of_repeats:'', no_of_units:''};

            arr.push({"activity_id":activity.key, "image_url": activity.image_url, "title":activity.title, "type":activity.type, "no_of_units":'', "no_of_repeats":''});
            this.setState({activities: arr});
    
            const state = this.state;
            state[this.state.day_period] = day_activities;
            this.setState({state});
        }
        else
        {
            alert('Exercise already assigned !!');
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.title === ''){
            alert('Please enter title..');
        }
        else if (this.state.description === ''){
            alert('Please enter description..');
        }
        else if (this.state.s_date === ''){
            alert('Please enter start date..');
        }
        else if (this.state.e_date === ''){
            alert('Please enter end date..');
        }
        else if (Object.keys(this.state.sun_am).length === 0){
            alert('Exercise not assigned to Sunday Morning');
        }
        else if (Object.keys(this.state.sun_pm).length === 0){
            alert('Exercise not assigned to Sunday Evening');
        }
        else if (Object.keys(this.state.mon_am).length === 0){
            alert('Exercise not assigned to Monday Morning');
        }
        else if (Object.keys(this.state.mon_pm).length === 0){
            alert('Exercise not assigned to Monday Evening');
        }
        else if (Object.keys(this.state.tue_am).length === 0){
            alert('Exercise not assigned to Tueday Morning');
        }
        else if (Object.keys(this.state.tue_pm).length === 0){
            alert('Exercise not assigned to Tueday Evening');
        }
        else if (Object.keys(this.state.wed_am).length === 0){
            alert('Exercise not assigned to Wednesday Morning');
        }
        else if (Object.keys(this.state.wed_pm).length === 0){
            alert('Exercise not assigned to Wednesday Evening');
        }
        else if (Object.keys(this.state.thu_am).length === 0){
            alert('Exercise not assigned to Thursday Morning');
        }
        else if (Object.keys(this.state.thu_pm).length === 0){
            alert('Exercise not assigned to Thursday Evening');
        }
        else if (Object.keys(this.state.fri_am).length === 0){
            alert('Exercise not assigned to Friday Morning');
        }
        else if (Object.keys(this.state.fri_pm).length === 0){
            alert('Exercise not assigned to Friday Evening');
        }                
        else if (Object.keys(this.state.sat_am).length === 0){
            alert('Exercise not assigned to Saturday Morning');
        }
        else if (Object.keys(this.state.sat_pm).length === 0){
            alert('Exercise not assigned to Saturday Evening');
        }                        
        else{
            const updateRef = firebase.database().ref(`workouts_v2/${this.state.key}`);
            updateRef.set({
                title: this.state.title,
                description: this.state.description,
                s_date: this.state.s_date,
                e_date: this.state.e_date,
                sun_am: this.state.sun_am,
                sun_pm: this.state.sun_pm,
                mon_am: this.state.mon_am,
                mon_pm: this.state.mon_pm,
                tue_am: this.state.tue_am,
                tue_pm: this.state.tue_pm,
                wed_am: this.state.wed_am,
                wed_pm: this.state.wed_pm,
                thu_am: this.state.thu_am,
                thu_pm: this.state.thu_pm,
                fri_am: this.state.fri_am,
                fri_pm: this.state.fri_pm,
                sat_am: this.state.sat_am,
                sat_pm: this.state.sat_pm,
                assigned: this.state.assigned
            }).then((docRef) => {
                // const user_groups = this.state.user_groups;
                // user_groups.forEach(grp =>
                //     firebase.database().ref(`user_group_workouts/${grp.key}/${this.state.key}`).set({
                //         title: this.state.title,
                //         s_date: this.state.s_date,
                //         e_date: this.state.e_date
                //     }).then((res)=>{})
                //     .catch((error) => {
                //         console.error("Error updating user group workout: ", error);
                //     })
                // );
                this.setState({
                    key: '',
                    title: '',
                    description: '',
                    s_date: '',
                    e_date: '',
                    day_period: '',
                    sun_am:{},
                    sun_pm:{},
                    mon_am:{},
                    mon_pm:{},
                    tue_am:{},
                    tue_pm:{},
                    wed_am:{},
                    wed_pm:{},
                    thu_am:{},
                    thu_pm:{},
                    fri_am:{},
                    fri_pm:{},
                    sat_am:{},
                    sat_pm:{},
                    assigned:0
                });
                this.props.history.push("/workouts/list");
            })
            .catch((error) => {
                console.error("Error updating workout: ", error);
            });
        }
    }

    render() {
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Edit Workout
                    <Link className="btn btn-primary float-right" to={`/workouts/list`}>Workouts List</Link>
                    </h3>
                </div>
                <div className="card-body scrollable-card-body">
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="title">Title:</label>
                                <textarea type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChange} placeholder="Title" cols="30" rows="2"></textarea>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="description">Description:</label>
                                <textarea type="text" className="form-control" name="description" value={this.state.description} onChange={this.onChange} placeholder="Description"  cols="30" rows="2"></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="s_date">Start Date:</label>
                                {
                                    this.state.assigned === 1?<label className="form-control">{new Intl.DateTimeFormat('en-GB').format(new Date(this.state.s_date))}</label>
                                    :<input type="date" className="form-control" dateformat="yyyy-mm-dd" name="s_date" value={this.state.s_date} onChange={this.onChange} placeholder="Start Date" />
                                }
                                
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="e_date">End Date:</label>
                                {
                                    this.state.assigned === 1?<label className="form-control">{new Intl.DateTimeFormat('en-GB').format(new Date(this.state.e_date))}</label>
                                    :<input type="date" className="form-control" dateformat="yyyy-mm-dd" name="e_date" value={this.state.e_date} onChange={this.onChange} placeholder="End Date" />
                                }
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="day_period">Select Day:</label>
                                <select className="form-control" name="day_period" value={this.state.day_period} onChange={this.onChange} placeholder="Select Day">
                                    <option value="-">Select Day</option>
                                    <option value="sun_am">Sunday Morning</option>
                                    <option value="sun_pm">Sunday Evening</option>
                                    <option value="mon_am">Monday Morning</option>
                                    <option value="mon_pm">Monday Evening</option>
                                    <option value="tue_am">Tuesday Morning</option>
                                    <option value="tue_pm">Tuesday Evening</option>
                                    <option value="wed_am">Wednesday Morning</option>
                                    <option value="wed_pm">Wednesday Evening</option>
                                    <option value="thu_am">Thursday Morning</option>
                                    <option value="thu_pm">Thursday Evening</option>
                                    <option value="fri_am">Friday Morning</option>
                                    <option value="fri_pm">Friday Evening</option>
                                    <option value="sat_am">Saturday Morning</option>
                                    <option value="sat_pm">Saturday Evening</option>                                                                                                                                                                                    
                                </select>
                            </div>                            
                        </div>   
                        {this.state.day_period !== "-"?
                        <div className="row">
                            <div className="col-md-6">
                                <div className="width-100"><h5>Exercise Assigned for Selected Day</h5></div>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-55">Exercise Title</th>
                                            <th className="width-15">Units</th>
                                            <th className="width-15">Repeats</th>
                                            <th className="width-15">..</th>
                                        </tr>
                                    </thead>
                                    <tbody className="height-100">
                                        {this.state.activities.map(activity =>
                                        <tr key={activity.activity_id}>
                                            <td>{activity.title}</td>
                                            <td>
                                                <input type="text" className="form-control" value={activity.no_of_units} onChange={e=>this.onColumnChange(activity.activity_id,'no_of_units',e.target.value)} placeholder="No Of Units" />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control" value={activity.no_of_repeats} onChange={e=>this.onColumnChange(activity.activity_id,'no_of_repeats',e.target.value)} placeholder="No Of Repeats" />
                                            </td>                                            
                                            <td><Link to="#" onClick={()=>this.delActivitiy(activity.activity_id)}>Remove</Link></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>  
                            </div>
                            <div className="col-md-6">
                                <div className="width-50 float-left">
                                    <h5>All Exercise</h5>
                                </div>
                                <input
                                    type="text" className="col-md-6 float-right"
                                    value={this.state.search_value}
                                    name="search_value"
                                    placeholder="Search..."
                                    onChange={this.onChange}
                                />                                
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-80">Exercise Title</th>
                                            <th className="width-20">..</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.filtered_activities.map(activity =>
                                        <tr key={`key${activity.key}`}>
                                            <td>{activity.title}</td>
                                            <td><Link to="#" onClick={()=>this.addActivitiy(activity)}>Add</Link></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>  
                            </div>                                                      
                        </div>
                        :<div></div>
                        }          
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default WorkoutEdit;