import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import '../../App.css';

class GalleryCreate extends Component {

    constructor() {
        super();
        this.ref = firebase.database().ref(`gallery_v2/${uuid()}`);
        this.ref_groups = firebase.database().ref('gallery_groups');
        this.storageRef = firebase.storage().ref('gallery');
        this.state = {
            content_type:'video',
            title: '',
            url: '',
            tag: '',
            group_id: '',
            groups: []
        };
    }

    componentDidMount() {
        this.ref_groups.on('value', snapshot => {
            let grps = [];
            let groups = snapshot.val();
            for (let group in groups) {
                grps.push({
                key: group,
                title: groups[group].title
                });
            };
            this.setState({ groups: grps });
        });
    }
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { content_type, title, url, tag, group_id } = this.state;

        if (url === ''){
            alert('Please enter url..');
        }
        else if (content_type === ''){
            alert('Please select content type..');
        }
        else if (tag === ''){
            alert('Please enter tag..');
        }                
        else if (title === ''){
            alert('Please enter title..');
        }
        else if (group_id === ''){
            alert('Please select user group..');
        }
        else{
            this.ref.set({
                content_type: this.state.content_type,
                title: this.state.title,
                tag: this.state.tag,
                url: this.state.url,
                group_id: this.state.group_id
            }).then((docRef) => {
                this.setState({
                    content_type:'',
                    title: '',
                    tag: '',
                    url: '',
                    group_id: ''
                });
                this.props.history.push("/gallery/list");
            })
            .catch((error) => {
                console.error("Error adding gallery: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        this.selectedFile = file;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }

    render() {
        const { content_type, title, url, tag, group_id } = this.state;
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Add Gallery
                    <Link className="btn btn-primary float-right" to="/gallery/list">Gallery List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="content_type">Content Type:</label>
                                <select className="form-control" name="content_type" value={content_type} onChange={this.onChange} placeholder="Select Content-Type">
                                    <option value="video">Video</option>
                                    <option value="pdf">PDF</option>
                                    <option value="image">Image</option>
                                </select>
                            </div>                        
                            <div className="form-group col-md-9">
                                <label htmlFor="title">Title:</label>
                                <input type="text" className="form-control" name="title" value={title} onChange={this.onChange} placeholder="Title" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="tag">Tag:</label>
                                <input type="text" className="form-control" name="tag" value={tag} onChange={this.onChange} placeholder="Tag" />
                            </div>              
                            <div className="form-group col-md-6">
                                <label className="width-100">Content Upload:</label>
                                <input type="file" onChange={this.handleSelectFile} />
                                <button type="button" onClick={this.uploadFile}>Upload Content File</button>
                            </div>
                        </div>                  
                        <div className="row">           
                            <div className="form-group col-md-12">
                                <label htmlFor="author">Url:</label>
                                <input type="text" className="form-control" name="url" value={url} onChange={this.onChange} placeholder="Url" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="content_type">Gallery Group:</label>
                                <select className="form-control" name="group_id" value={group_id} onChange={this.onChange} placeholder="Select User Group">
                                <option key="-" value="">Select Gallery Group</option>
                                {this.state.groups.map(group =>
                                    <option key={group.key} value={group.key}>{group.title}</option>
                                )}
                                </select>
                            </div>                    
                        </div>
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default GalleryCreate;