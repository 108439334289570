import React, { Component } from 'react';
import firebase from '../../Firebase';
import { Link } from 'react-router-dom';
import '../../App.css';

class GalleryEdit extends Component {

    constructor(props) {
        super(props);
        this.ref_groups = firebase.database().ref('gallery_groups');
        this.storageRef = firebase.storage().ref('gallery');
        this.state = {
        key: '',
        content_type: '',
        title: '',
        tag: '',
        url: '',
        group_id: '',
        groups: []        
        };
    }

    componentDidMount() {
        this.ref_groups.on('value', snapshot => {
            let grps = [];
            let groups = snapshot.val();
            for (let group in groups) {
                grps.push({
                key: group,
                title: groups[group].title
                });
            };
            this.setState({ groups: grps });
        });

        const ref = firebase.database().ref(`gallery_v2/${this.props.match.params.id}`);
        ref.on('value', snapshot => {
            let gallery = snapshot.val();
            if (gallery) {
                this.setState({
                    key: this.props.match.params.id,
                    content_type: gallery.content_type,
                    title: gallery.title,
                    tag: gallery.tag === undefined ? "" : gallery.tag,
                    url: gallery.url,
                    group_id: gallery.group_id === undefined ? "" : gallery.group_id
                });
            } else {
                console.log("No such gallery!");
            }        
        });
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({board:state});
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { content_type, title, url, tag, group_id } = this.state;

        if (url === ''){
            alert('Please enter url..');
        }
        else if (title === ''){
            alert('Please enter title..');
        }
        else if (group_id === ''){
            alert('Please select user group..');
        }        
        else{
            const updateRef = firebase.database().ref(`gallery_v2/${this.state.key}`);
            updateRef.set({
                content_type,
                title,
                url,
                group_id,
                tag
            }).then((docRef) => {
                this.setState({
                    key: '',
                    content_type: '',
                    title: '',
                    tag: '',
                    url: '',
                    group_id: ''
                });
                this.props.history.push("/gallery/list");
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
    }

    handleSelectFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        this.selectedFile = file;
    }

    uploadFile = (e) => {
        e.preventDefault();
        if (this.selectedFile === undefined || this.selectedFile === null || this.selectedFile === "")
        {
            alert('Please select file..');
        }
        else{
            const selectedFile = this.storageRef.child(this.selectedFile.name);
            selectedFile.put(this.selectedFile).then((snapshot)=>{
                selectedFile.getDownloadURL().then((fileurl)=>{
                    this.setState({url:fileurl});
                    alert('File uploaded successfully !!');
                })
            });
        }
    }

    render() {
        return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                    Edit Gallery
                    <Link className="btn btn-primary float-right" to={`/gallery/list`}>Gallery List</Link>
                    </h3>
                </div>
                <div className="card-body">
                    
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="title">Content-Type:</label>
                                <select className="form-control" name="content_type" value={this.state.content_type} onChange={this.onChange} placeholder="Select Content-Type">
                                    <option value="video">Video</option>
                                    <option value="pdf">PDF</option>
                                    <option value="image">Image</option>
                                </select>
                            </div>                
                            <div className="form-group col-md-9">
                                <label htmlFor="title">Title:</label>
                                <input type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChange} placeholder="Title" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="tag">Tag:</label>
                                <input type="text" className="form-control" name="tag" value={this.state.tag} onChange={this.onChange} placeholder="Tag" />
                            </div>              
                            <div className="form-group col-md-6">
                                <label className="width-100">Content Upload:</label>
                                <input type="file" name="upload" onChange={this.handleSelectFile} />
                                <button type="button" onClick={this.uploadFile}>Upload File</button>
                            </div>
                        </div>  
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="url">Url:</label>
                                <input type="text" className="form-control" name="url" value={this.state.url} onChange={this.onChange} placeholder="Url" />
                            </div>
                        </div>    
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="content_type">Gallery Group:</label>
                                <select className="form-control" name="group_id" value={this.state.group_id} onChange={this.onChange} placeholder="Select User Group">
                                <option key="-" value="">Select Gallery Group</option>
                                {this.state.groups.map(group =>
                                    <option key={group.key} value={group.key}>{group.title}</option>
                                )}
                                </select>
                            </div>                        
                        </div>
                        <button type="submit" className="btn btn-success">Submit</button>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default GalleryEdit;