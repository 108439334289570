import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import uuid from 'react-uuid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../App.css';

class WorkoutList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('workouts_v2');   
        this.loggedIn = false; 
        this.mounted = false;
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false, 
            search_value:'',       
            workouts: [],
            filtered_workouts: []
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true });
            this.ref.on('value', snapshot => {
                let wos = [];
                let workouts = snapshot.val();
                for (let workout in workouts) {
                    wos.push({
                        key: workout,
                        title: workouts[workout].title,
                        s_date: workouts[workout].s_date,
                        e_date: workouts[workout].e_date
                    });
                };
                this.setState({ workouts: wos.sort(this.getSortOrder("title")), filtered_workouts:wos.sort(this.getSortOrder("title")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterWorkouts();
        }        
    }    

    filterWorkouts(){
        let workouts = this.state.workouts;
        let filtered_wos = workouts;
        if (this.state.search_value !== ""){
            filtered_wos = workouts.filter(wo => 
                wo.title.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1
            );
        }
        this.setState({filtered_workouts:filtered_wos});
    }

    createCopy(wo){
        confirmAlert({
            title: 'Are you sure.. You want to create a copy?',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.oldRef = firebase.database().ref(`workouts_v2/${wo.key}`);
                    this.newRef = firebase.database().ref(`workouts_v2/${uuid()}`);
                    this.oldRef.on('value', snapshot => {
                        let oldWorkout = snapshot.val();   
                        oldWorkout.title = oldWorkout.title + " - Copy";
                        oldWorkout.assigned = 0;
                        this.newRef.set(oldWorkout);
                    });
                }
              },
              {
                label: 'No',
                onClick: () => {

                }
              }
            ]
          });        
    }

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title col-md-5 float-left">
                            Workouts List
                            </h3>
                            <Link className="btn btn-primary float-right" to="/workouts/create">Add Workout</Link>
                            <input
                                type="text" className="col-md-3 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by Title..."
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="width-55">Title</th>
                                        <th className="width-15">Start Date</th>
                                        <th className="width-15">End Date</th>
                                        <th className="width-15">..</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtered_workouts.map(workout =>
                                    <tr key={workout.key}>
                                        <td><Link to={`/workouts/edit/${workout.key}`}>{workout.title}</Link></td>
                                        <td>{new Intl.DateTimeFormat('en-GB').format(new Date(workout.s_date))}</td>
                                        <td>{new Intl.DateTimeFormat('en-GB').format(new Date(workout.e_date))}</td>
                                        <td>
                                            <Link to="#" onClick={()=>this.createCopy(workout)}>Create Copy</Link>
                                        </td>                                        
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
    }
}

export default WorkoutList;
