import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class GalleryList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('gallery_v2');
        this.ref_groups = firebase.database().ref('gallery_groups');
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false,
            search_value:'',
            gallery: [],
            filtered_gallery: [],
            groups: []
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true});

            this.ref_groups.on('value', snapshot => {
                let grps = [];
                let groups = snapshot.val();
                for (let group in groups) {
                    grps.push({
                    key: group,
                    title: groups[group].title
                    });
                };
                this.setState({ groups: grps });
            });

            this.ref.on('value', snapshot => {
                let gal = [];
                let galleries = snapshot.val();
                for (let gallery in galleries) {
                    //const { content_type, title, url } = galleries[gallery];
                    gal.push({
                    key: gallery,
                    content_type: galleries[gallery].content_type,
                    title: galleries[gallery].title,
                    url: galleries[gallery].url,
                    group_id: galleries[gallery].group_id
                    });
                };
                this.setState({ gallery: gal.sort(this.getSortOrder("title")), filtered_gallery: gal.sort(this.getSortOrder("title")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    getGroupName(id){
        let grps = this.state.groups;
        let grp = grps.filter(grp=>grp.key===id);
        if (grp[0])
            return grp[0]['title'];
        else
            return "";
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterGallery();
        }        
    }    

    filterGallery(){
        let gallery = this.state.gallery;
        let filtered_gall = gallery;
        if (this.state.search_value !== ""){
            filtered_gall = gallery.filter(gal => 
                gal.title.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1
            );
        }
        this.setState({filtered_gallery:filtered_gall});
    }

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else    
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title col-md-5 float-left">
                            Gallery List
                            </h3>   
                            <Link className="btn btn-primary float-right" to="/gallery/create">Add Gallery</Link>
                            <input
                                type="text" className="col-md-3 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by Title..."
                                onChange={this.onChange}
                            />                               
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="width-10">Content Type</th>
                                        <th className="width-30">Title</th>
                                        <th className="width-40">Url</th>
                                        <th className="width-20">User Group</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtered_gallery.map(gallery =>
                                    <tr key={gallery.key}>
                                        <td>{gallery.content_type}</td>
                                        <td><Link to={`/gallery/edit/${gallery.key}`}>{gallery.title}</Link></td>
                                        <td>{gallery.url}</td>
                                        <td>{this.getGroupName(gallery.group_id)}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
    }
}

export default GalleryList;
