import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class UserGroupList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('user_groups');
        this.ref_group_workouts = firebase.database().ref('user_group_workouts');
        this.loggedIn = false; 
        this.mounted = false;
        this.currDate = new Date(new Date().toISOString().substring(0,10));
        console.log(this.currDate);
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false,        
            groups: [],
            all_group_workouts: [],
            group_workouts: [],
            selectedGroupId:"",
            selectedGroupName:""
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }
    
    getSortOrderDesc(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return -1;    
            } else if (a[prop] < b[prop]) {    
                return 1;    
            }    
            return 0;    
        }    
    }

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true });

            this.ref_group_workouts.on('value', snapshot => {
                let allwrks = [];
                let usergroups = snapshot.val();
                for (let grp in usergroups) {
                    allwrks.push({
                    key: grp,
                    workouts: this.getWorkouts(usergroups[grp])
                    });
                };
                this.setState({ all_group_workouts: allwrks });
            });            

            this.ref.on('value', snapshot => {
                let grps = [];
                let groups = snapshot.val();
                for (let group in groups) {
                    grps.push({
                    key: group,
                    group_name: groups[group].group_name
                    });
                };
                this.setState({ groups: grps.sort(this.getSortOrder("group_name")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    getWorkouts(group) {
        let arr = [];
        for (const key in group) {
            let wo = group[key];
            arr.push({"workout_id": key, "title":wo.title, "s_date": wo.s_date, "e_date": wo.e_date, "active": wo.active});
        }
        return arr;
    }

    showWorkouts(group_id, group_name){
        let usergroup = this.state.all_group_workouts.filter(usergroup=>usergroup.key===group_id)[0];
        if (usergroup && usergroup.workouts.length > 0)
            this.setState({"selectedGroupId":group_id, "selectedGroupName": group_name, "group_workouts": usergroup.workouts.sort(this.getSortOrderDesc("s_date"))});
        else
            this.setState({"selectedGroupId":group_id, "selectedGroupName": group_name, "group_workouts":[]});
    }

    toggleStatus(workout){
        const ref_userGroup = firebase.database().ref(`user_group_workouts/${this.state.selectedGroupId}`);
        ref_userGroup.child(workout.workout_id).update({active:workout.active===1?0:1})
        let group_wos = this.state.group_workouts;
        group_wos.forEach(wo=>{
            if (wo.workout_id===workout.workout_id)
                wo.active = workout.active===1?0:1;
        });
        this.setState({group_workouts:group_wos});
    }

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="width-40 float-left">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                User Group List
                                <Link className="btn btn-primary float-right" to="/user_groups/create">Add User Group</Link>
                                </h3>
                                <h6>click workout link to load workouts of user groups..</h6>
                            </div>
                            
                            <div className="card-body">
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-80">Group Name</th>
                                            <th className="width-20">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.groups.map(group =>
                                        <tr key={group.key}>
                                            <td><Link to={`/user_groups/edit/${group.key}`}>{group.group_name}</Link></td>
                                            <td><Link to="#" onClick={()=>this.showWorkouts(group.key, group.group_name)}>Workout</Link></td>
                                            {/* <td><Button variant="link" onClick={()=>this.showWorkouts(group.key, group.group_name)}>Workout</Button></td> */}
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    {this.state.selectedGroupId !== "" ?
                    <div className="width-60 float-right">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                Assigned Workouts
                                <Link className="btn btn-primary float-right" to={`/user_groups/assign_workouts/${this.state.selectedGroupId}`}>Assign Workout</Link>
                                </h3>
                                <h6>{this.state.selectedGroupName}</h6>
                            </div>
                            
                            <div className="card-body">
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="width-50">Workout Name</th>
                                            <th className="width-15">End Date</th>
                                            <th className="width-10">Active</th>
                                            <th className="width-15">..</th>
                                            <th className="width-10">..</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.group_workouts.map(wo =>
                                        <tr key={wo.workout_id}>
                                            <td>{wo.title}</td>
                                            {/* <td>{wo.s_date}</td> */}
                                            <td>{new Intl.DateTimeFormat('en-GB').format(new Date(wo.e_date))}</td>
                                            <td>{wo.active===1?"Yes":"No"}</td>
                                            <td>
                                                <Link to="#" onClick={()=>this.toggleStatus(wo)}>{wo.active===1?"De-Activate":"Activate"}</Link>
                                            </td>
                                            <td>
                                                <Link to="#" onClick={()=>this.toggleStatus(wo)}>{wo.s_date?"Remove":""}</Link>
                                            </td>                                            
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div> 
                    :<div className="width-60 float-right"></div>
                    }                 
                </div>
            );
    }
}

export default UserGroupList;
