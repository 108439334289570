import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class GalleryGroupList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('gallery_groups');
        //this.user_ref = firebase.database().ref('users');
        this.loggedIn = false; 
        this.mounted = false;
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false,   
            search_value: '',     
            groups: [],
            filtered_groups: []
            //unassigned_users: [],
            //assigned_users: []
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true });
            this.ref.on('value', snapshot => {
                let grps = [];
                let groups = snapshot.val();

                for (let group in groups) {
                    grps.push({
                    key: group,
                    title: groups[group].title,
                    image_url: groups[group].image_url
                    });
                };
                this.setState({ groups: grps.sort(this.getSortOrder("title")),filtered_groups: grps.sort(this.getSortOrder("title")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterGroups();
        }        
    }    

    filterGroups(){
        let groups = this.state.groups;
        let filtered_grps = groups;
        if (this.state.search_value !== ""){
            filtered_grps = groups.filter(grp => 
                grp.title.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1
            );
        }
        this.setState({filtered_groups:filtered_grps});
    }

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title col-md-5 float-left">
                            Gallery Group List
                            </h3>
                            <Link className="btn btn-primary float-right" to="/gallery_groups/create">Add Gallery Group</Link>
                            <input
                                type="text" className="col-md-3 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by Title..."
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="width-30">Group Title</th>
                                        <th className="width-70">Image Url</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtered_groups.map(group =>
                                    <tr key={group.key}>
                                        <td><Link to={`/gallery_groups/edit/${group.key}`}>{group.title}</Link></td>
                                        <td>{group.image_url}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
    }
}

export default GalleryGroupList;
