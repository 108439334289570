import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserGroupEdit from './components/user_groups/edit';
import UserGroupCreate from './components/user_groups/create';
import AssignWorkouts from './components/user_groups/assign_workouts';
import UserGroupList from './components/user_groups/list';
import WorkoutEdit from './components/workouts/edit';
import WorkoutCreate from './components/workouts/create';
import WorkoutList from './components/workouts/list';
import ActivityEdit from './components/activities/edit';
import ActivityCreate from './components/activities/create';
import ActivityList from './components/activities/list';
import GalleryGroupEdit from './components/gallery_groups/edit';
import GalleryGroupCreate from './components/gallery_groups/create';
import GalleryGroupList from './components/gallery_groups/list';
import GalleryEdit from './components/gallery/edit';
import GalleryCreate from './components/gallery/create';
import GalleryList from './components/gallery/list';
import UserEdit from './components/users/edit';
import UserList from './components/users/list';

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <Router>
    <div>
      <Route exact path='/' component={App} />
      <Route path='/user_groups/edit/:id' component={UserGroupEdit} />
      <Route path='/user_groups/create' component={UserGroupCreate} />
      <Route path='/user_groups/assign_workouts' component={AssignWorkouts} />
      <Route path='/user_groups/list' component={UserGroupList} />            
      <Route path='/workouts/edit/:id' component={WorkoutEdit} />
      <Route path='/workouts/create' component={WorkoutCreate} />
      <Route path='/workouts/list' component={WorkoutList} />
      <Route path='/activities/edit/:id' component={ActivityEdit} />
      <Route path='/activities/create' component={ActivityCreate} />
      <Route path='/activities/list' component={ActivityList} />      
      <Route path='/gallery_groups/edit/:id' component={GalleryGroupEdit} />
      <Route path='/gallery_groups/create' component={GalleryGroupCreate} />
      <Route path='/gallery_groups/list' component={GalleryGroupList} />                  
      <Route path='/gallery/edit/:id' component={GalleryEdit} />
      <Route path='/gallery/create' component={GalleryCreate} />
      <Route path='/gallery/list' component={GalleryList} />      
      <Route path='/users/edit/:id' component={UserEdit} />
      <Route path='/users/list' component={UserList} />            
    </div>
  </Router>,  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
