import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import firebase from '../../Firebase';
import { Table } from 'react-bootstrap';
import '../../App.css';

class ActivityList extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.database().ref('activities');   
        this.loggedIn = false; 
        this.mounted = false;
        this.state = {
            loading: false,
            mounted: false,
            loggedIn: false, 
            search_value: '',       
            activities: [],
            filtered_activities: []
        };
    }

    getSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    componentDidMount() {
        if (localStorage.getItem('loggedin') !== null && localStorage.getItem('loggedin') !== ''){
            this.setState({loggedIn: true, loading: true });
            this.ref.on('value', snapshot => {
                let acts = [];
                let activities = snapshot.val();
                for (let activity in activities) {
                    acts.push({
                        key: activity,
                        title: activities[activity].title,
                        attachment_type: activities[activity].attachment_type,
                        no: activities[activity].no.toString(),
                        type: this.getType(activities[activity].type)
                    });
                };
                this.setState({ activities: acts.sort(this.getSortOrder("no")),  
                    filtered_activities: acts.sort(this.getSortOrder("no")), loading: false });
            });
        }
        this.setState({mounted: true});
    }

    getType(type){
        if (type === null || type === undefined)
            return "";
        else if (type === "1")
            return "Warm Up";
        else if (type === "2")
            return "Excercise";            
        else if (type === "3")
            return "Cool Down";  
        else if (type === "4")
            return "Rest";                        
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState({state});
        if (e.target.name === "search_value"){
            this.filterActivities();
        }        
    }    

    filterActivities(){
        let activities = this.state.activities;
        let filtered_acts = activities;
        if (this.state.search_value !== ""){
            filtered_acts = activities.filter(activity => 
                activity.title.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1 || activity.no.toLowerCase().indexOf(this.state.search_value.toLowerCase()) > -1
            );
        }
        this.setState({filtered_activities:filtered_acts});
    }        

    render() {
        const { loading } = this.state;
        if (this.state.mounted === true && this.state.loggedIn === false){
            return <Redirect to="/login" />;
        }
        else
            return (
                <div className="container">
                    {loading && <div>Loading ...</div>}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title col-md-5 float-left">
                            Exercise List
                            </h3>
                            <Link className="btn btn-primary float-right" to="/activities/create">Add Exercise</Link>
                            <input
                                type="text" className="col-md-3 form-control float-right"
                                value={this.state.search_value}
                                name="search_value"
                                placeholder="Search by Title / Exercise #..."
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="width-15">Exercise #</th>
                                        <th className="width-15">Content Type</th>
                                        <th className="width-10">Type</th>
                                        <th className="width-60">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filtered_activities.map(activity =>
                                    <tr key={activity.key}>
                                        <td>{activity.no}</td>
                                        <td>{activity.attachment_type}</td>
                                        <td>{activity.type}</td>
                                        <td><Link to={`/activities/edit/${activity.key}`}>{activity.title}</Link></td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
    }
}

export default ActivityList;
